import $ from 'jquery';

var scrollToQuestion = function (next) {
	var nextPosition = next.offset().top + $('.inside-app-frame').scrollTop();
	$('.inside-app-frame').animate({ scrollTop: nextPosition - 100 }, 'slow');
};

var reorderQuestionContainers = function () {
	var n = $('.question-container-fader').length;
	for (var i = 0; i < n - 1; i++) {
		for (var j = 0; j < n - 1 - i; j++) {
			var containers = $('.question-container-fader');
			var p = containers.eq(j);
			var q = containers.eq(j + 1);

			if (p.find('.question-container').hasClass('question-error')
				&& !q.find('.question-container').hasClass('question-error')) {
				p.insertAfter(q);
			}
		}
	}

	// If there are any questions with errors, scroll to the first of them:
	var questionsWithErrors = $('.question-error');
	if (questionsWithErrors.length > 0) {
		var error = questionsWithErrors.first();
		if (error.length !== 0) {
			scrollToQuestion(error);
		}
	}
}

window.jQuery.validator.unobtrusive.options = {
	errorPlacement: function (error, element) {
		// Only affect question container elements with this override:
		var parents = element.parents('.question-container');
		if (parents.length > 0) {
			// Only apply to parents that don't have an error designation yet:
			var targetParents = parents.not('.question-error');
			if (targetParents.length > 0) {
				targetParents.addClass('question-error');
				targetParents.prepend('<div class="question-error-container"><span class="question-error-icon"><i class="fa fa-warning"></i></span><span>' + error.html() + '</span></div>');
			}
		}
	},
	success: function (error) {
		var element = $(this);
		if (error.length > 0) {
			var id = error[0].id;
			var name = id.replace(/-error$/, '');

			// Only affect question container elements with this override:
			var targets = element.find('.question-container').has('[name="' + name + '"]');
			if (targets.length > 0) {
				targets.each(function (index, targetElem) {
					var target = $(targetElem);
					if (target.hasClass('question-error')) {
						target.removeClass('question-error');
						target.find('.question-error-container').remove();
					}
				});
			}

			reorderQuestionContainers();
		}
	},
	invalidHandler: function (event, validator) {
		reorderQuestionContainers();
	}
};