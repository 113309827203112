import $ from 'jquery';

var customValidationRequiredEitherOr = function (value, element, parameters, property1name, desiredvalue1, actualvalue1, property2name, desiredvalue2, actualvalue2) {
	if ($.trim(desiredvalue1).toLowerCase() === $.trim(actualvalue1).toLocaleLowerCase()
		&& $.trim(desiredvalue2).toLowerCase() === $.trim(actualvalue2).toLocaleLowerCase()) {
		//var isValid = $.validator.methods.required.call(this, value, element, parameters);
		//return isValid;

		// Fake it out since retriggering validation will cause an infinite loop:
		$('#' + property2name).removeClass('valid');
		$('#' + property2name).addClass('input-validation-error');
		$('span[data-valmsg-for=' + property2name + ']').empty();
		$('span[data-valmsg-for=' + property2name + ']').removeClass('field-validation-valid');
		$('span[data-valmsg-for=' + property2name + ']').addClass('field-validation-error');
		$('span[data-valmsg-for=' + property2name + ']').append('<span for="' + property2name + '" class="">' + $('#' + property2name).attr('data-val-requiredeitheror') + '</span > ');

		return false;
	}

	// Fake it out since retriggering validation will cause an infinite loop:
	$('#' + property2name).removeClass('input-validation-error');
	$('#' + property2name).addClass('valid');
	$('span[data-valmsg-for=' + property2name + ']').empty();
	$('span[data-valmsg-for=' + property2name + ']').removeClass('field-validation-error');
	$('span[data-valmsg-for=' + property2name + ']').addClass('field-validation-valid');

	return true;
};

var customValidationGetActualValue = function (propname) {
	var controlType1 = $("input[id$='" + propname + "']").attr("type");
	var actualvalue = {}
	if (controlType1 == "checkbox" || controlType1 == "radio") {
		var control = $("input[id$='" + propname + "']:checked");
		actualvalue = control.val();
	}
	else if (controlType1 == "tel") {
		actualvalue = $("#" + propname).val();
		actualvalue = actualvalue.replace(/[\(\)\-_\s]/g, '');

		if (!/^\d{10}$/.test(actualvalue)) {
			actualvalue = '';
		}
	}
	else {
		actualvalue = $("#" + propname).val();
	}

	return actualvalue;
};

// Exports
window.customValidationRequiredEitherOr = customValidationRequiredEitherOr;
window.customValidationGetActualValue = customValidationGetActualValue;