import $ from 'jquery';
import 'jquery-mask-plugin';

$(document).ready(function () {
	$('.phone-control').mask('(000) 000-0000', { selectOnFocus: true });
	$('.zipcode-control').mask('00000', { selectOnFocus: true });
	$('.datepicker-control').mask('00/00/0000', { selectOnFocus: true });
	$('.card-expiration-control').mask('00/0000', { selectOnFocus: true });

	$('.timepicker-control').mask('Hh:Mm Pp', {
		placeholder: 'hh:mm am',
		translation: {
			'H': {
				pattern: /[0-1]/,
				optional: true
			},
			'h': {
				pattern: /[0-9]/,
				optional: false
			},
			'M': {
				pattern: /[0-5]/,
				optional: false
			},
			'm': {
				pattern: /[0-9]/,
				optional: false
			},
			'P': {
				pattern: /[aApP]/,
				optional: false
			},
			'p': {
				pattern: /[mM]/,
				optional: false
			}
		},
	});
});