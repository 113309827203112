import $ from 'jquery';
import 'jquery-validation';
require('../assessment/assessment-jquery-validation-unobtrusive.js'); // Must be loaded prior to jquery-validation-unobtrusive and via require
import 'jquery-validation-unobtrusive';

$.validator.unobtrusive.adapters.add('requiredif', ['property1name', 'desiredvalue1'], function (options) {
	options.rules['requiredif'] = options.params;
	options.messages['requiredif'] = options.message;
});

$.validator.addMethod('requiredif', function (value, element, parameters) {
	var desiredvalue1 = parameters.desiredvalue1;
	desiredvalue1 = (desiredvalue1 == null ? '' : desiredvalue1).toString();

	var property1name = parameters.property1name;
	var actualvalue1 = customValidationGetActualValue(property1name);

	if ($.trim(desiredvalue1).toLowerCase() === $.trim(actualvalue1).toLocaleLowerCase()) {
		var isValid = $.validator.methods.required.call(this, value, element, parameters);
		return isValid;
	}

	return true;
});	