import $ from 'jquery';
import 'jquery-validation';
require('../assessment/assessment-jquery-validation-unobtrusive.js'); // Must be loaded prior to jquery-validation-unobtrusive and via require
import 'jquery-validation-unobtrusive';

$.validator.unobtrusive.adapters.add('requiredeitheror', ['property1name', 'desiredvalue1', 'property2name', 'desiredvalue2'], function (options) {
	options.rules['requiredeitheror'] = options.params;
	options.messages['requiredeitheror'] = options.message;
});

$.validator.addMethod('requiredeitheror', function (value, element, parameters) {
	var desiredvalue1 = parameters.desiredvalue1;
	desiredvalue1 = (desiredvalue1 == null ? '' : desiredvalue1).toString();

	var desiredvalue2 = parameters.desiredvalue2;
	desiredvalue2 = (desiredvalue2 == null ? '' : desiredvalue2).toString();

	var property1name = parameters.property1name;
	var property2name = parameters.property2name;

	var actualvalue1 = customValidationGetActualValue(property1name);
	var actualvalue2 = customValidationGetActualValue(property2name);

	return customValidationRequiredEitherOr(value, element, parameters, property1name, desiredvalue1, actualvalue1, property2name, desiredvalue2, actualvalue2);
});	