import $ from 'jquery';
import 'jquery-validation';
require('../assessment/assessment-jquery-validation-unobtrusive.js'); // Must be loaded prior to jquery-validation-unobtrusive and via require
import 'jquery-validation-unobtrusive';

$.validator.unobtrusive.adapters.add('requiredeitherorif', ['property1name', 'desiredvalue1', 'property2name', 'desiredvalue2', 'ifpropertyname', 'ifpropertyvalue'], function (options) {
	options.rules['requiredeitherorif'] = options.params;
	options.messages['requiredeitherorif'] = options.message;
});

$.validator.addMethod('requiredeitherorif', function (value, element, parameters) {
	var desiredvalue1 = parameters.desiredvalue1;
	desiredvalue1 = (desiredvalue1 == null ? '' : desiredvalue1).toString();

	var desiredvalue2 = parameters.desiredvalue2;
	desiredvalue2 = (desiredvalue2 == null ? '' : desiredvalue2).toString();

	var desiredifvalue = parameters.ifpropertyvalue;
	desiredifvalue = (desiredifvalue == null ? '' : desiredifvalue).toString();

	var property1name = parameters.property1name;
	var property2name = parameters.property2name;
	var ifpropertyname = parameters.ifpropertyname;

	var actualvalue1 = customValidationGetActualValue(property1name);
	var actualvalue2 = customValidationGetActualValue(property2name);
	var actualifvalue = customValidationGetActualValue(ifpropertyname);

	if ($.trim(actualifvalue).toLowerCase() === $.trim(desiredifvalue).toLocaleLowerCase()) {
		return customValidationRequiredEitherOr(value, element, parameters, property1name, desiredvalue1, actualvalue1, property2name, desiredvalue2, actualvalue2);
	}

	return true;
});	